<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div v-if="data" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar Campanha</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-group">
              <label for="demoTextInput1">{{$tc('generic-str.name', 1)}}</label>
              <input type="text" class="form-control" v-model="form.name" />
            </div>
            <sms-input
              v-model="form.body"
              :normalize="form.normalize"
              :variables="vars"
            />
            <div class="custom-control custom-checkbox form-check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                v-model="form.normalize"
              />
              <label class="custom-control-label" for="customCheck1"
                >{{$t('template-component.replace')}}</label
              >
            </div>
            <date-picker
              v-model="form.scheduled_at"
              :config="options"
              class="form-control input-date"
              :placeholder="$t('generic-str.date-hour')"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-bootstrap-datetimepicker';
import SmsService from '@/services/sms.service';
import Modal from '@/mixins/Modal';
import SmsInput from '@/components/form/SmsInput.vue';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    DatePicker,
    SmsInput,
  },
  data() {
    return {
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      options: {
        format: 'YYYY-MM-DD HH:mm:ss',
        useCurrent: true,
        keepInvalid: true,
      },
      isSending: false,
    };
  },
  methods: {
    save() {
      this.isSending = true;
      SmsService.editCampaign(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Campanha editada',
            type: 'success',
          });
          this.$emit('submit');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
</style>
